@import '@/assets/styles';

.button {
  @include flex(center, center);
  gap: 8px;
  border: none;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);

  :global(.icon) {
    font-size: 14px;
  }

  :global(.icon),
  :global(.text) {
    color: var(--light) !important;
  }

  &__default {
    background-color: var(--light);
    border: 1px solid #d9d9d9;

    :global(.icon),
    :global(.text) {
      color: var(--secondary-2) !important;
    }

    &:hover {
      background-color: var(--light) !important;
      border: 1px solid #d9d9d9;
    }

    &:disabled,
    &:disabled:hover {
      background-color: var(--light) !important;
      opacity: 0.5;
    }

    svg {
      fill: black !important;
    }
  }

  &__orange {
    background-color: var(--secondary-3);

    &:hover {
      background-color: #f7b13c !important;
    }

    &:disabled,
    &:disabled:hover {
      background-color: #f7b13c !important;
      opacity: 0.5;
    }
  }

  &__green {
    background-color: var(--secondary-4);

    &:hover {
      background-color: #0fc569 !important;
    }

    &:disabled,
    &:disabled:hover {
      background-color: #0fc569 !important;
      opacity: 0.5;
    }
  }

  &__purple {
    background-color: var(--primary-2);

    &:hover {
      background-color: #797bf5 !important;
    }

    &:disabled,
    &:disabled:hover {
      background-color: #797bf5 !important;
      opacity: 0.5;
    }
  }

  &__red {
    background-color: var(--secondary-5);

    &:hover {
      background-color: #bb1818 !important;
    }

    &:disabled,
    &:disabled:hover {
      background-color: #bb1818 !important;
      opacity: 0.5;
    }
  }

  &__border--purple {
    background-color: var(--light);
    border: 1px solid var(--primary-2);

    :global(.text) {
      color: var(--primary-2) !important;
    }

    &:hover {
      background-color: var(--primary-2);
    }

    &:disabled,
    &:disabled:hover {
      background-color: var(--primary-2);
      opacity: 0.5;
    }
  }
}

.disabled {
  background-color: red;
}

.circle {
  width: 32px;
  border-radius: 50%;
  position: relative;

  :global(.ant-btn-loading-icon) {
    position: absolute;
    margin-inline-end: unset !important;
  }
}

.loading__center {
  :global(.ant-btn-loading-icon) {
    position: absolute;
    margin-inline-end: unset !important;
  }
}
