@import '@/assets/styles';

.header,
.footer {
  padding: 16px;
}

.content {
  flex: 1;

  :global(.ant-pagination) {
    @include flex(center, unset);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  :global(.ant-table-wrapper),
  :global(.ant-spin-nested-loading),
  :global(.ant-spin-container) {
    height: 100%;
  }
}

.modal {
  &__confirm {
    :global(.ant-modal-confirm-body-wrapper) {
      padding: 24px;
    }

    :global(.anticon-exclamation-circle) {
      display: none;
    }

    :global(.ant-modal-confirm-btns) {
      @include flex(flex-end, center);
    }
  }
}

.approve {
  :global(.ant-btn-primary) {
    background-color: var(--primary-2);
    border: var(--primary-2);

    &:hover {
      background-color: #797bf5 !important;
      border: #797bf5;
    }
  }
}
