@import './../mixin';

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: var(--primary-2);
}

.switch-form {
  margin-bottom: 0;
}
