@import './../mixin';

.ant-modal {
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-close {
    top: 22px;
  }

  .ant-modal-header {
    margin-bottom: 0;
    padding: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
