@import '@/assets/styles';

.sidebar {
  @include flex(unset, center, column);
  gap: 24px;
  box-sizing: border-box;
  width: 72px;
  min-width: 72px;
  height: calc(100vh - 64px);
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.04);

  &__item {
    @include flex(center, center);
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 8px;

    &:hover,
    &.active {
      background-color: var(--primary-2) !important;

      span {
        color: var(--light) !important;
      }
    }

    &:first-child {
      margin-top: 24px;
    }
  }
}
