@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?qqu3v');
  src:  url('fonts/icomoon.eot?qqu3v#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?qqu3v') format('truetype'),
    url('fonts/icomoon.woff?qqu3v') format('woff'),
    url('fonts/icomoon.svg?qqu3v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unsubcribe:before {
  content: "\e92a";
}
.icon-check-circle:before {
  content: "\e929";
}
.icon-notification:before {
  content: "\e928";
}
.icon-resume:before {
  content: "\e927";
}
.icon-add-calendar:before {
  content: "\e926";
}
.icon-loading-processing:before {
  content: "\e925";
}
.icon-cloud-upload:before {
  content: "\e924";
}
.icon-uncheck:before {
  content: "\e923";
}
.icon-about:before {
  content: "\e91f";
}
.icon-reply:before {
  content: "\e920";
}
.icon-eye1:before {
  content: "\e921";
}
.icon-mail1:before {
  content: "\e922";
}
.icon-linkedin:before {
  content: "\e91e";
}
.icon-close-circle:before {
  content: "\e91c";
}
.icon-resend:before {
  content: "\e91d";
}
.icon-plus-circle:before {
  content: "\e91a";
}
.icon-clock-redo:before {
  content: "\e91b";
}
.icon-arrow-left:before {
  content: "\e919";
}
.icon-desc:before {
  content: "\e918";
}
.icon-check:before {
  content: "\e90c";
}
.icon-redo:before {
  content: "\e90d";
}
.icon-filter:before {
  content: "\e90e";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-down-circle:before {
  content: "\e910";
}
.icon-warning:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e912";
}
.icon-back:before {
  content: "\e913";
}
.icon-edit:before {
  content: "\e914";
}
.icon-eye:before {
  content: "\e915";
}
.icon-mail:before {
  content: "\e916";
}
.icon-asc:before {
  content: "\e917";
}
.icon-send:before {
  content: "\e906";
}
.icon-logout:before {
  content: "\e907";
}
.icon-plus:before {
  content: "\e908";
}
.icon-download:before {
  content: "\e909";
}
.icon-upload:before {
  content: "\e90a";
}
.icon-user:before {
  content: "\e90b";
}
.icon-campaign:before {
  content: "\e900";
}
.icon-chat:before {
  content: "\e901";
}
.icon-dashboard:before {
  content: "\e902";
}
.icon-lead:before {
  content: "\e903";
}
.icon-search:before {
  content: "\e904";
}
.icon-settings:before {
  content: "\e905";
}
