@import '@/assets/styles';

.preload {
  @include flex(center, center);
  width: 100vw;
  height: 100vh;
  animation: pulse 1.3s infinite linear;

  img {
    width: 200px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}
