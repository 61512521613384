@import './../mixin';

.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled) {
  &:hover {
    border-color: var(--primary-2);
  }
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: var(--primary-2);
}
