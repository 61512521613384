@import './../mixin';

.ant-form-item-control-input .ant-input {
  @include text(14px, 20px, 400);
  padding: 5px 12px;
  font-family: 'Poppins';
  height: 32px;
}

.ant-form-item-label {
  padding-bottom: 4px !important;
  color: var(--secondary-2) !important;

  label {
    &::after {
      display: none;
    }
  }
}

.ant-form-item-required {
  @include flex(flex-end, center, row-reverse);

  &::before {
    margin-inline-end: unset;
    margin-inline-start: 4px;
  }

  &::after {
    display: none;
  }
}

.ant-form-item {
  margin-bottom: 16px;
}
