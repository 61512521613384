@mixin flex($jc, $ai, $fd: row) {
  display: flex;
  flex-direction: $fd;
  justify-content: $jc;
  align-items: $ai;
}

@mixin text($fs, $lh, $fw: unset) {
  font-size: $fs;
  line-height: $lh;
  font-weight: $fw;
}

@mixin textOverflow($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
