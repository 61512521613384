@import './../mixin';

.ant-input {
  @include text(14px, 20px, 400);
  padding: 12px 16px;
  font-family: 'Poppins';
  height: 44px;
  border: 1px solid #d9d9d9;
}

.ant-input-disabled {
  color: var(--secondary-2) !important;
}

.input-readonly {
  outline: none;
  border: none;
  background-color: unset !important;
  padding: 5px 0 !important;
  color: var(--sub-1) !important;
}
