@import './../mixin';

.ant-pagination {
  .ant-pagination-item-active {
    background-color: var(--primary-2);
    border: none;

    a,
    &:hover a {
      color: var(--light);
    }
  }
}
