:root {
  --primary-1: #2e3192;
  --primary-2: #5a5ce2;
  --secondary-1: #92a4e2;
  --secondary-2: #181922;
  --secondary-3: #f59e0b;
  --secondary-4: #00ab55;
  --secondary-5: #9f0000;
  --sub-1: #525a5f;
  --sub-2: #818181;
  --sub-3: #f9f9f9;
  --sub-4: #f6f6f6;
  --sub-5: #eceff3;
  --sub-6: #eef2ff;
  --sub-7: ##b7b9bd;
  --light: #ffffff;
  --blue: #1677ff;
  --red: #e01306;
  --border: #d9d9d9;
}
