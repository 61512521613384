@import '@/assets/styles';

.space {
  &__dashboard {
    display: flex;
    width: 100vw;
    height: calc(100vh - 64px);
    margin-top: 64px;

    :global(.ant-space-item) {
      height: 100%;
    }
  }

  &__public {
    @include flex(center, center);
    width: 100vw;
    height: 100vh;
    background-color: #f7f9ff;
    position: relative;
    overflow: hidden;
    background-image: url('../assets/images/login-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    &-image {
      position: absolute;
      width: 100%;
      min-width: 1440px;
      bottom: 0;
    }
  }
}

.layout {
  &__admin {
    width: 100%;
    padding: 24px;
    background-color: var(--sub-6);
    overflow-x: hidden;
    max-height: calc(100vh - 64px);

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }
}

.expire-overlay {
  height: 100vh;
  position: absolute;
  background-color: var(--light);
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
}
