@import '@/assets/styles';

.text {
  &-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-10 {
    @include text(10px, 20px);
  }
  &-12 {
    @include text(12px, 20px);
  }
  &-14 {
    @include text(14px, 22px);
  }
  &-16 {
    @include text(16px, 24px);
  }
  &-20 {
    @include text(20px, 28px);
  }
  &-24 {
    @include text(24px, 32px);
  }
  &-30 {
    @include text(30px, 38px);
  }
  &-38 {
    @include text(38px, 46px);
  }
  &-40 {
    @include text(40px, 40px);
  }
  &-44 {
    @include text(44px, 64px);
  }
  &-46 {
    @include text(46px, 54px);
  }
  &-56 {
    @include text(56px, 64px);
  }
  &-68 {
    @include text(68px, 76px);
  }
}
