@import './mixin';
@import './variables';

.layout-container {
  height: 100%;
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--light);
  border-radius: 8px;
}

.select-tags {
  .ant-select-arrow {
    display: none;
  }
}

.confirm-modal {
  .ant-modal-body {
    padding: 24px !important;
  }

  .anticon-exclamation-circle {
    display: none;
  }

  .ant-btn-primary {
    background-color: var(--primary-2);

    &:hover {
      background-color: #797bf5 !important;
    }

    &:disabled,
    &:disabled:hover {
      background-color: #797bf5 !important;
      opacity: 0.5;
    }
  }
}

.wmde-markdown {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0.04) !important;
  overflow-y: auto;
  color: var(--secondary-2) !important;
}

.quill {
  .ql-editor {
    height: 300px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }
}

.indicator-icon {
  position: absolute !important;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto !important;
  font-size: 32px !important;
}

.notMargin {
  margin-bottom: 0;
}

.text-link {
  color: var(--primary-2);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.disabled-dropdown {
  opacity: 0.2;
}

.expired-modal {
  width: 500px !important;

  .ant-modal-confirm-body-wrapper {
    padding: 24px !important;
  }

  .anticon-check-circle {
    display: none;
  }

  .ant-modal-confirm-paragraph {
    max-width: 100% !important;
  }
}

.dropdown {
  top: 65px !important;

  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    padding: 12px !important;
    border-radius: unset !important;

    &:hover {
      border-radius: unset;
    }

    &:first-child:hover {
      border-radius: 8px 8px 0 0 !important;
    }

    &:last-child:hover {
      border-radius: 0 0 8px 8px !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--sub-5);
    }

    .icon {
      margin-right: 6px;
    }
  }
}

.error-row {
  background-color: #f6e6e6;
}

.border-top {
  border-top: 1px solid #bfc9eb;
}
