@import '@/assets/styles';

.header {
  @include flex(space-between, center);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-sizing: border-box;
  height: 64px;
  padding: 16px 24px;
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--light);
}

.logo {
  user-select: none;
}

.user {
  &__container {
    gap: 8px;
    user-select: none;
    cursor: pointer;
  }

  &__avatar {
    height: 32px;
    width: 32px;
    border: none;
  }

  &__system {
    @include flex(center, center);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--sub-6);
  }
}

.notification {
  width: 330px;
  top: 65px !important;

  :global(.ant-popover-inner) {
    @include flex(unset, unset, column-reverse);
    padding: 0;
  }

  :global(.ant-popover-title) {
    margin: 0;
    padding: 10px;
    cursor: pointer;
    border-top: 1px solid var(--sub-5);
  }

  :global(.ant-popover-inner-content) {
    max-height: calc(115px * 5);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #707070;
    }
  }

  &-item {
    padding: 12px;

    &:hover {
      background-color: var(--sub-6);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--sub-5);
    }
  }
}

.text {
  &-seemore {
    color: var(--primary-2);
    cursor: pointer;
  }
}

.modal-body {
  padding: 24px;
  max-height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
}
