@import '@/assets/styles';

.loading__container {
  @include flex(center, center);

  :global(.ant-spin-nested-loading) {
    width: 100%;
  }

  :global(.ant-spin-nested-loading > div > .ant-spin) {
    max-height: unset;
  }
}
