@import './../mixin';

.ant-table {
  .ant-table-container {
    border-radius: unset;
  }

  .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: unset;
  }

  .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: unset;
  }

  .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
    background-color: var(--sub-6);
  }

  .ant-table-wrapper {
    overflow-x: auto;
  }
}

// .ant-table-body {
//   overflow: auto !important;
// }

.ant-table-wrapper .ant-table .ant-table-header {
  border-radius: unset;
}

.ant-table-content {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(82, 90, 95, 0.2);
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(82, 90, 95, 0.5);
  }
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-inline-start: 1px solid rgba($color: black, $alpha: 0.1);
  border-top: 1px solid rgba($color: black, $alpha: 0.1);
}

.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-inline-end: 1px solid rgba($color: black, $alpha: 0.1);
}

.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1px solid rgba($color: black, $alpha: 0.1);
}

.ant-table-cell p {
  @include textOverflow(1);
}
