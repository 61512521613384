@import '@/assets/styles';

.isFooter {
  :global(.ant-modal-footer) {
    @include flex(flex-end, center);
    margin-top: 0;
    padding: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.back-button {
  @include flex(center, center);
  cursor: pointer;
}
